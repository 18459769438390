/* @font-face {
  font-family: "Money";
  src: url("../public/Money-plus.ttf") format("truetype");
  font-display: swap;
} */

:root {
  --color-primary: #e9613f;
  --color-primary-hover: #ed713f;
  --color-primary-active: #f2883f;
  --color-dark: #000;
  --color-bg: #232020;
  --color-mg: #666;
  --color-fg: #fff;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0px;
  padding: 0px;
  border: none;
  min-height: 100vh;
  background: var(--color-bg);
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-fg);
}

a {
  color: var(--color-fg);
  text-decoration: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  transition: scale 0.1s ease-in-out;
}

a:hover {
  border-color: var(--color-primary);
}

a:active {
  scale: 0.98;
}

button {
  background: var(--color-primary);
  border: 2px solid var(--color-dark);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
  padding: 10px 20px;
  font-weight: bold;
  color: var(--color-fg);
}

button:hover:not(:disabled) {
  background: var(--color-primary-hover);
}

button:active:not(:disabled) {
  border-color: var(--color-primary-active);
}

button:disabled {
  background: var(--color-mg);
}

input {
  border: 2px solid var(--color-bg);
  background: var(--color-bg);
  border: 2px solid var(--color-primary);
  color: var(--color-fg);
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.1rem;
  outline: 0;
  font-family: inherit;
}

input:hover:not(:disabled) {
  background: var(--color-primary-hover);
}

input:focus:not(:disabled) {
  background: var(--color-primary-hover);
  border-color: var(--color-primary-active);
  color: var(--color-bg);
}

input:disabled {
  background: var(--color-mg);
}
