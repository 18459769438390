.container {
  padding: 30px 0px 0px 0px;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  align-items: center;
  grid-gap: 10px;
  margin-top: 200px;
}

.logo {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.quantitySelector {
  color: var(--color-fg);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
}

.buttonSmall {
  width: 30px;
  height: 30px;
  font-size: 1.1rem;
  padding: 0px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  margin-top: 20px;
}
